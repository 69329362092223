import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Pill from '../components/pill'
import PublicationCard from '../components/publicationCard'
import styles from '../styles/member.module.css'
import BackArrow from '../assets/back-arrow.svg'
import LinkedInIcon from '../assets/linkedIn.svg'
import WebsiteIcon from '../assets/website.svg'

const Member = ({data}) => {

    const { airtableNetwork:member } = data
	const {Picture:picture, Publications:publications, Display_name:name, Expertise:expertise, LinkedIn:linkedIn, Website:website, Institution:institution, Role:role, Last_name:lastname, First_name:firstname} = member.data
    if(publications) {
        publications.forEach(publication => {
            publication.data.Date = new Date(publication.data.Date)
        })

    }
    return (
        <Layout>
            <Link to={`../`}><div className={styles.back}><BackArrow/><span>Back to network</span></div></Link>
            <div className={styles.member}>
                <div className={styles.header}>
                    {
                        picture && (<div className={styles.picture} style={{backgroundImage: `url(${picture[0].url})`}}/>)
                    }
                    <h2>{name}</h2>
                    <p>{institution?.[0].data.Name}</p>
                    <div>
                        {
                            role.split('\n').map((role, i) => <p key={i} className={styles.role}>{role}</p>)
                        }
                    </div>
                    <div className={styles.expertise}>
                        {
                            expertise?.sort((a, b) => a.localeCompare(b)).map((expertise, i) => (
                                <Pill key={i} link={`/network?expertise=${expertise}`} label={expertise}/>
                            ))
                        }
                    </div>
                    <div className={styles.links}>
                        {
                            linkedIn && (
                                <a href={linkedIn} target='_blank' rel='noreferrer'>
                                    <LinkedInIcon/>
                                    <span>LinkedIn</span>
                                </a>
                            )
                        }
                        {
                            website && (
                                <a href={website} target='_blank' rel='noreferrer'>
                                    <WebsiteIcon/>
                                    <span>Website</span>
                                </a>
                            )
                        }
                    </div>
                </div>
                {
                  publications && (
                        <>
                            <h3>Related publications</h3>
                            <div className={styles.publications}>
                                {

                                    publications.sort((a,b) => b.data.Date.getTime() - a.data.Date.getTime()) .map( (publication, i) => (
                                      !publication.data.HideFromNetwork &&    (
                                        <PublicationCard key={i} title={publication.data.Title} author={publication.data.Author} source={publication.data.Source} date={publication.data.Date}
                                                         abstract={publication.data.Abstract}
                                                         highlightAuthor={[lastname,firstname]} />
                                    )
                                    )
                                    )

                                }
                            </div>
                        </>
                    )
                }

            </div>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        airtableNetwork(data: {Slug: {eq: $slug}}) {
            data {
                Picture {
                    url
                }
                Publications {
                    data {
                        Title
                        Abstract
                        Source
                        Author
                        Date
                        HideFromNetwork
                    }
                }
                Display_name
                Expertise
                LinkedIn
                Website
                Institution{
                    data {
                        Name
                    }
                }
                Role
                First_name
                Last_name
                Slug
            }
        }
    }
`

export default Member
